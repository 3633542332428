module.exports = function (ngModule) {
  class Service {
    constructor($http) {
      'ngInject';

      this.$http = $http;
    }
    createHistory(organizationId, historyObj) {
      return this.$http.post(`history/organization/${organizationId}`, historyObj).then((result) => result.data);
    }
    getAllHistory(organizationId, referenceId) {
      return this.$http
        .get(`history/organization/${organizationId}/referenceId/${referenceId}`)
        .then((result) => result.data.data);
    }
    updateHistory(organizationId, historyId, historyObj) {
      return this.$http
        .patch(`history/${historyId}`, historyObj)
        .then((result) => result.data);
    }
  }

  ngModule.service('historyService', Service);
};
