class Controller {
  constructor($q, $uibModal, historyService, growl) {
    'ngInject';

    this.$q = $q;
    this.$uibModal = $uibModal;
    this.historyService = historyService;
    this.growl = growl;
  }
  $onInit() {
    this.historyService
      .getAllHistory(this.user.orgContext.id, this.referenceId)
      .then((history) => this.history = history);
  }
  addHistory() {
    return this.$uibModal
      .open({
        component: 'frHistoryModal',
        backdrop: 'static',
        resolve: {
          user: () => this.user,
          maxVersion: () => this.history.length ? _.maxBy(this.history, 'version').version : 0,
        },
      })
      .result.then((publishObj) => {
        publishObj.type = this.type;
        publishObj.referenceId = this.referenceId;
        this.historyService
          .createHistory(this.user.orgContext.id, publishObj)
          .then(() => {
            this.historyService
              .getAllHistory(this.user.orgContext.id, this.referenceId)
              .then((history) => this.history = history);
            this.growl.success('Successfully updated version');
          })
          .catch(() => this.growl.error('Error occurred during updated version'));
      });
  }

  updateHistory(data) {
    if (data) {
      this.historyService
        .updateHistory(this.user.orgContext.id, data.id, {comment: data.comment})
        .then(() => {
          data.showDescription = false;
          this.growl.success('Update Successfully');
        })
        .catch(() => this.growl.error('Error occurred during update.'));
    }
  }
}

module.exports = Controller;
