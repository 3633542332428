module.exports = function(constantsService, ingredients) {
  'ngInject';

  const vm = this;

  vm.$onInit = async function () {
    vm.company = vm.resolve.company;
    vm.isFoodProduct = vm.resolve.isFoodProduct;
    vm.user = vm.resolve.user;
    vm.item = vm.resolve.item;
    vm.plan = vm.resolve.plan;
    this.organizationId = vm.plan.organizationId || this.user.organizationId;

    if(vm.item) {
      vm.uom = vm.item.uom.id;
      vm.itemName = vm.item.itemName;
      vm.comment = vm.item.comment;
      vm.plan = vm.item.plan;
    }
    await constantsService.getConstants('inventoryTypes').then(result => vm.inventoryTypes = result.data);
    await constantsService.getConstants('inventoryUOM').then(result => vm.inventoryUOM = result.data);

    if(!vm.isFoodProduct) {
      vm.inventoryTypes = vm.inventoryTypes.filter(item => item.code !== 'FP');
    }
  };

  vm.cancel = function() {
    vm.modalInstance.dismiss('cancel');
  };

  vm.save = function() {
    vm.saving = true;
    vm.status = 'active';
    const item = {
      inventoryType: vm.inventoryType || vm.inventoryTypes.find(item => item.code === 'FP').id,
      uom: vm.uom,
      status: vm.status,
      itemName: vm.itemName,
      comment: vm.comment,
      plan: vm.plan,
    };

    if(vm.item) {
      ingredients.updateIngredient(vm.item.id, item)
        .then((result) => vm.modalInstance.close(result))
        .catch((err) => vm.modalInstance.dismiss(err));
    } else {
      ingredients.pushIngredient(item)
        .then((result) => vm.modalInstance.close(result))
        .catch((err) => vm.modalInstance.dismiss(err));
    }
  };

};
