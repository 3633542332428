class Controller {
  constructor(
    $state,
    $scope,
    $log,
    products,
    growl,
    subscriptionService,
    utils,
    $timeout,
    planTypes,
    orgPerspectives,
    constantsService,
    $q,
    hazardsService,
    $window,
    V2_URL,
    reportsService,
    controlsService,
    $stateParams,
    $uibModal,
    confirmDeleteModal,
    confirmModal,
    $rootScope,
    authorization,
    IngredientSearch,
    InventoryItemsSearch,
    ingredientService,
    equipmentService,
    foodProductService,
    miniTourService,
    deviceDetector,
    drift,
    EquipmentSearch
  ) {
    'ngInject';

    this.$state = $state;
    this.$scope = $scope;
    this.$log = $log;
    this.products = products;
    this.growl = growl;
    this.subscriptionService = subscriptionService;
    this.hazardsSvc = hazardsService;
    this.controlsSvc = controlsService;
    this.utils = utils;
    this.$timeout = $timeout;
    this.planTypes = planTypes;
    this.orgPerspectives = orgPerspectives;
    this.constantsService = constantsService;
    this.$q = $q;
    this.$window = $window;
    this.V2_URL = V2_URL;
    this.reportsService = reportsService;
    this.$stateParams = $stateParams;
    this.$uibModal = $uibModal;
    this.confirmDeleteModal = confirmDeleteModal;
    this.confirmModal = confirmModal;
    this.isHazardDirty = false;
    this.isControlDirty = false;
    this.pageTransitionDelay = 600;
    this.$rootScope = $rootScope;
    this.authorization = authorization;
    this.IngredientSearch = IngredientSearch;
    this.InventoryItemsSearch = InventoryItemsSearch;
    this.EquipmentSearch = EquipmentSearch;
    this.ingredientSvc = ingredientService;
    this.equipmentSvc = equipmentService;
    this.foodProductSvc = foodProductService;
    this.drift = drift;
    this.miniTourService = miniTourService;
    this.deviceDetector = deviceDetector;
    this.hazardSlideCss = 'slide-out-right slide-in-right';
    this.controlSlideCss = 'slide-out-right slide-in-right';
  }

  $onInit() {
    this.products.updateLastViewed(this.product.$id);
    const onPayAsGo = this.user.onPayAsGoPlan() && !this.user.hasPermission(this.authorization.claims.CF_ADMIN);

    this.planPurchased = !onPayAsGo || this.product.chargeId || this.product.unlockedBy;
    this.facilitySops = this.facilitySops || {};
    this.planSops = this.sops || {};
    this.$timeout(() => {
      this.$stateParams.activeView = this.$stateParams.activeView || 'diagram';
      this.$stateParams.activeTab = this.$stateParams.activeTab || 'info';
    });

    this.isCfAdmin = this.user.isCfAdmin();
    this.organizationId = this.isCfAdmin ? (this.product.organizationId || this.user.organizationId) : this.user.orgContext.id;

    this.hazardsSvc.$getProductHazards(this.product.$id).then($hazards => this.$hazards = $hazards);
    this.products.externalFiles(this.product.$id).then(files => {this.files = files;});

    if (this.$stateParams.hazardId) {
      this.hazardsSvc.$getHazard(this.product.$id, this.$stateParams.hazardId)
        .then($hazard => {
          this.$selectedHazard = $hazard;
        });
    }

    if (this.$stateParams.controlId) {
      this.controlsSvc
        .$getControl(this.product.$id, this.$stateParams.controlId)
        .then(control => this.$selectedControl = control);
      this.hazardSlideCss = 'slide-out-left slide-in-left';
    }

    this.loadEquipment();
    this.loadIngredients();
    this.loadFoodProducts();

    this.$scope.$watch(
      'vm.$stateParams',
      val => {
        this.$state.go(this.$state.current.name, val, {notify: false});
      },
      true
    );

    this.lastSaved = this.product.updatedOn || this.product.createdOn;

    if (!this.product.isSampleProduct) {
      const planTypeText = this.product.planType === this.planTypes.HACCP_PLAN.id ? 'HACCP' : 'Food Safety';

      this.$timeout(() => {
        this.miniTourService.enqueueTour(this.user, {
          id: 'productEditIntro',
          type: this.miniTourService.type.CENTERED,
          title: `Welcome to Your ${planTypeText} Plan`,
          width: 400,
          contentHtml: '<div class="p-3">FoodReady provides a split screen showing the two main parts ' +
            `of a ${planTypeText} plan, the <b>Plan Details</b> on the left and ` +
            'the <b>Process Flow Diagram</b> on the right.</div>'
        });
        if (this.deviceDetector.isMobile()) {
          this.miniTourService.enqueueTour(this.user, {
            id: 'flowMobileReadOnly',
            title: 'Process Flow Chart Mobile Mode',
            contentHtml: 'Due to limitations with mobile devices, the FoodReady interactive process flow ' +
              'does not allow adding or removing process steps while in mobile mode. Please visit FoodReady on ' +
              'a laptop or desktop to add new steps to your process flow. Sorry for the inconvenience.'
          });
        } else if (!this.isReadOnly) {

          this.miniTourService.enqueueTour(this.user, {
            id: 'productDiagramIntro',
            selector: '.products-edit #diagram-tour-marker',
            placement: 'left',
            title: 'Process Flow Diagram',
            width: 520,
            contentHtml: '<div class="mb-4">The flow diagram is a pictorial representation of your entire food ' +
              'production process. Begin by dragging your first process step from the palette or adding a step from ' +
              'the toolbar. Steps may include (but are not limited to) receiving, ingredient storage, preparation, ' +
              'cooking, cooling, packing, labelling, bulk storage and distribution.</div>'
          });
          this.startPlanTour();
        }
      });
    }
  }

  startPlanTour() {
    this.$timeout(() => {
      this.miniTourService.enqueueTour(this.user, {
        id: 'addNewProcessStepTour',
        selector: '.products-edit #new-step-tour-marker',
        placement: 'left',
        title: 'Add Process Step',
        width: 370,
        contentHtml: '<div class="mb-4">Drag this <b>New Step</b> button to create a new Process Step.</div>' +
          '<img height="320" width="350" alt="Drag New Step" src="https://static.foodready.ai/static/img/gif/add-new-process-step.gif"/ >'
      });

      this.miniTourService.enqueueTour(this.user, {
        id: 'addFoodProductTour',
        selector: '.products-edit #food-products',
        placement: 'right',
        title: 'Add Food Product',
        width: 370,
        contentHtml: '<div class="mb-4">Add a <b>Food Product</b> associated with the Plan</div>' +
          '<img height="320" width="350" alt="Add Food Product" src="https://static.foodready.ai/static/img/gif/add-food-product.gif"/ >'
      });

      this.miniTourService.enqueueTour(this.user, {
        id: 'addEquipmentTour',
        selector: '.products-edit #equipment',
        placement: 'right',
        title: 'Add Equipment',
        width: 370,
        contentHtml: '<div class="mb-4">Add <b>Equipment Used</b> to produce your food products</div>' +
          '<img height="320" width="350" alt="Add Equipment" src="https://static.foodready.ai/static/img/gif/add-equipment.gif"/ >'
      });
    });
  }

  chat() {
    // this.gtag.event('edit_plan_prof_services_chat', {
    //   'event_category': 'sales',
    //   value: this.user.uid
    // });

    this.drift.startInteraction(362394);    // Drift: Expert Services - Plan Help Slideout
  }

  async loadFoodProducts() {
    this.foodProducts = [];

    if (!this.product?.foodProducts) {
      return;
    }

    const ids = Object.keys(this.product.foodProducts);
    const ingredientIds = ids.join(',');
    const searchObject = new this.InventoryItemsSearch(this.organizationId).size(999).itemIds(ingredientIds);

    try {
      const inventoryItems = await searchObject.search();
      const inventoryItemsMap = inventoryItems.reduce((acc, item) => {
        if(ids.includes(item._id)) {
          delete item.firebase_id;
        }
        acc[item.firebase_id || item.id] = { ...item, $id: item.firebase_id || item.id };
        return acc;
      }, {});

      this.foodProducts = Object.entries(this.product.foodProducts)
        .map(([id]) => inventoryItemsMap[id])
        .filter(item => item !== undefined);

    } catch (error) {
      console.error("Failed to load Food Products:", error);
    }
  }

  async loadEquipment() {
    this.equipment = [];

    if (!this.product?.equipment) {
      return;
    }
    const ids = Object.keys(this.product.equipment);
    const equipmentIds = ids.join(',');
    const searchObject = new this.EquipmentSearch(this.organizationId).size(999).equipmentIds(equipmentIds);
    try {
      let equipments = await searchObject.search();
      const equipmentsMap = equipments.reduce((acc, item) => {
        if(ids.includes(item._id)) {
          delete item.firebase_id;
        }
        acc[item.firebase_id || item.id] = { ...item, $id: item.firebase_id || item.id };
        return acc;
      }, {});

      this.equipment = Object.entries(this.product.equipment).map(([id, { processSteps }]) => {
        if (processSteps && equipmentsMap[id]) {
          return { ...equipmentsMap[id], processSteps };
        }
        return equipmentsMap[id];
      }).filter(equipment => equipment !== undefined);

    } catch (error) {
      console.error("Failed to load equipments:", error);
    }
  }

  async loadIngredients() {
    this.ingredients = [];

    if (!this.product?.ingredients) {
      return;
    }
    const ids = Object.keys(this.product.ingredients);
    const ingredientIds = ids.join(',');
    const searchObject = new this.InventoryItemsSearch(this.organizationId).size(999).itemIds(ingredientIds);

    try {
      let inventoryItems = await searchObject.search();
      const inventoryItemsMap = inventoryItems.reduce((acc, item) => {
        if(ids.includes(item._id)) {
          delete item.firebase_id;
        }
        acc[item.firebase_id || item.id] = { ...item, $id: item.firebase_id || item.id };
        return acc;
      }, {});

      this.ingredients = Object.entries(this.product.ingredients).map(([id, { processSteps }]) => {
        if (processSteps && inventoryItemsMap[id]) {
          return { ...inventoryItemsMap[id], processSteps };
        }
        return inventoryItemsMap[id];
      }).filter(item => item !== undefined);

    } catch (error) {
      console.error("Failed to load ingredients:", error);
    }
  }

  navToTab(tabName) {
    if (this.$stateParams.activeTab === tabName) {
      return;
    }

    if (this.$stateParams.activeTab === 'hazards' && this.$stateParams.hazardId) {
      this.$stateParams.hazardId = null;
    }

    if (
      tabName !== 'hazards' && (this.$stateParams.activeView === 'control' && this.isControlDirty) ||
      this.$stateParams.activeView === 'hazard' && this.isHazardDirty
    ) {
      let bodySnippet = this.$stateParams.activeView === 'control' ? 'control' : 'hazard';
      let iconCss = this.$stateParams.activeView === 'control' ? 'fa-thermometer' : 'fa-exclamation-triangle';

      return this.confirmModal({
        title: `<span class="far ${iconCss} fa-fw"></span> Work in Progress...`,
        body:
          `Please save or cancel your work on the ${bodySnippet} (right pane) before ` +
          `switching to <strong>${tabName}</strong>.`,
        hideCancelButton: true
      });
    }

    if (this.$stateParams.activeView === 'control' || this.$stateParams.activeView === 'hazard') {
      this.$timeout(() => this.$rootScope.$broadcast('updateDiagram'));
      this.$timeout(() => {
        this.hazardSlideCss = 'slide-out-right slide-in-right';
      }, this.pageTransitionDelay * 2);

      if (this.$selectedHazard) {
        this.$selectedHazard.$destroy();
        this.$selectedHazard = null;
        this.selectedHazard = null;
        this.$stateParams.hazardId = null;
      }

      if (this.$selectedControl) {
        this.$selectedControl.$destroy();
        this.$selectedControl = null;
        this.$stateParams.controlId = null;
      }
    }

    this.$stateParams.activeView = 'diagram';
    this.$stateParams.activeTab = tabName;
  }

  editHazard(hazard, stepId) {
    if (this.$stateParams.activeView === 'control') {
      if (this.isControlDirty) {
        return this.confirmModal({
          title: '<span class="far fa-thermometer fa-fw"></span> Unsaved Changes',
          body:
            'Please save or cancel your work on the preventive control (right pane) before ' +
            (hazard ? `editing hazard <strong>${hazard.name}</strong>.` : 'creating a new hazard.'),
          hideCancelButton: true
        });
      }

      if (_.get(this.$selectedHazard, '$id', null) !== _.get(hazard, '$id')) {
        this.controlSlideCss = '';
        this.hazardSlideCss = '';
      }
    } else if (
      this.$stateParams.activeView === 'hazard' &&
      _.get(this.$selectedHazard, '$id', null) !== _.get(hazard, '$id')
    ) {
      if (this.isHazardDirty) {
        return this.confirmModal({
          title: '<span class="far fa-exclamation-triangle fa-fw"></span> Unsaved Changes',
          body:
            'Please save or cancel your work on the hazard (right pane) before ' +
            (hazard ? `editing hazard <strong>${hazard.name}</strong>.` : 'creating a new hazard.'),
          hideCancelButton: true
        });
      }
    } else if (_.get(this.$selectedHazard, '$id', null) === _.get(hazard, '$id')) {
      return this.$q.resolve(true);
    }

    const openHazard = (hazard) => this.$timeout(() => {
      this.selectedHazard = hazard ? this.selectedHazard : null;
      this.$selectedHazard = hazard;
      this.$stateParams.activeView = 'hazard';
      this.$stateParams.hazardId = this.$selectedHazard.$id;

      this.$timeout(() => {
        this.controlSlideCss = 'slide-out-right slide-in-right';
        this.hazardSlideCss = 'slide-out-right slide-in-right';
      }, this.pageTransitionDelay * 2);
    });

    if (!hazard) {
      return this.hazardsSvc.createHazard(this.user, this.product, this.$hazards, stepId)
        .then((hazards) => {
          if (hazards.length === 1 && _.first(hazards).$value === null) {
            openHazard(_.first(hazards));
          } else {
            this.growl.success('Hazards imported successfully.');
          }
        })
        .catch(err => {
          if (err.message !== 'duplicate') { return this.$q.reject(err); }
          this.editHazard(err.duplicate);
        });
    } else {
      return this.hazardsSvc.$getHazard(this.product.$id, hazard.$id)
        .then(result => openHazard(result))
        .catch(err => this.utils.defaultErrorHandler(err, 'Unable to add hazard'));
    }
  }

  removeHazard(hazard) {
    return this.confirmDeleteModal('Hazard', {
      body:
        `Are you sure you want to remove hazard <strong>${hazard.name}</strong>? All associated controls will ` +
        'also be removed.'
    }).then(() => {
      const planSopIds = _.reduce(
        this.product.controls,
        (result, control) => {
          if (control?.hazards[hazard.$id]) {
            _.each(control.sops, (sop, sopId) => result.push(sopId));
          }

          return result;
        },
        []
      );

      return this.hazardsSvc
        .remove(this.product, hazard.$id)
        .then(() => _.each(planSopIds, sopId => delete this.planSops[sopId]));
    });
  }

  removeControl(controlId) {
    const control = this.product.controls[controlId];

    if (!control) {
      throw new Error('Control not found: ' + controlId);
    }
    return this.confirmDeleteModal('Control', {
      headerHtml: '<span class="text-danger">Remove <strong>Control</strong>?</span>',
      confirmText: 'Remove',
      body: `Are you sure you want to remove the <strong>${control.type}</strong> control?`
    }).then(() => this.controlsSvc.$removeControl(this.product.$id, controlId));
  }

  addIngredient(stepId) {
    let searchObject = new this.InventoryItemsSearch(this.organizationId);
    searchObject = searchObject.inventoryTypes('WIP,SI');

    return this.$uibModal.open({
      component: 'cfChooseFromListModal',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        itemName: () => 'Ingredient',
        allowSkip: true,
        multiple: true,
        skipButtonClass: () => 'u-btn-primary',
        skipButtonHtml: () => '<i class="far fa-plus mr-2"></i> Add New Ingredient',
        header: () => '<i class="far fa-leaf mr-2"></i> Select Ingredient',
        searchObject: () => searchObject,
        columns: () => [
          {title: 'Name', property: 'itemName'},
          {title: 'Status', property: 'status'},
          {title: 'Description', property: 'comment'},
        ],
        noneFoundHtml: () => 'No ingredients found.'
      }
    }).result
      .then((ingredients) => {
        return ingredients ? ingredients : this.$window.open(`${this.V2_URL}/item-register`, '_blank');
      })
      .then((ingredients) => {
        return this.$q.all(_.map(ingredients, (ingredient) => {
          // If ingredient already exists, either skip it or add the desired step (if applicable).
          let foundIngredient = _.find(this.ingredients, (i) => (i.$id || i.id)  === (ingredient.$id || ingredient.id));

          if (foundIngredient) {
            if (stepId) {
              foundIngredient.processSteps = foundIngredient.processSteps || {};
              foundIngredient.processSteps[stepId] = true;
              return this.products.addIngredientProcessStep(this.product.$id, ingredient.$id || ingredient.id , stepId);
            }
            return;
          }
          this.products.addIngredient(this.product.$id, ingredient.$id || ingredient.id)
            .then(() => {
              this.ingredients.push(ingredient);
              if (stepId) {
                ingredient.processSteps = {};
                ingredient.processSteps[stepId] = true;
                return this.products.addIngredientProcessStep(this.product.$id, ingredient.$id || ingredient.id, stepId);
              }
            });
        }));
      }).catch(err => this.utils.defaultErrorHandler(err, err?.data?.message));
  }

  addEquipment(stepId) {
    return this.$uibModal
      .open({
        component: 'cfChooseFromListModal',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          itemName: () => 'Equipment',
          allowSkip: true,
          skipButtonClass: () => 'u-btn-primary',
          skipButtonHtml: () => '<i class="far fa-plus mr-2"></i> Add New Equipment',
          header: () => '<i class="far fa-conveyor-belt mr-2"></i> Select Equipment',
          itemsArray: () => this.equipmentSvc.query(this.organizationId, '', 0, 10000)
            .then((result) => _.filter(result, eq =>
              !_.get(this.product, `equipment[${eq.$id}].processSteps[${stepId}]`))),
          columns: () => [
            {title: 'Name', property: 'name'},
            {title: 'Code', property: 'code'},
            {title: 'Make', property: 'make'},
            {title: 'Model', property: 'model'},
            {title: 'Serial No.', property: 'serialNumber'}
          ],
          noneFoundHtml: () => 'No equipment found.'
        }
      }).result
      .then((equipment) => {
        if(equipment) {

          let foundEquipment = _.find(this.equipment, (i) => (i.id || i.$id) === (equipment.id || equipment.$id));
          if (foundEquipment) {
            if (stepId) {
              foundEquipment.processSteps = foundEquipment.processSteps || {};
              foundEquipment.processSteps[stepId] = true;
              return this.products.addEquipmentProcessStep(this.product.$id, equipment.id || equipment.$id, stepId);
            }
            return;
          }
          return this.products.addEquipment(this.product.$id, equipment.id || equipment.$id)
            .then(() => {
              this.equipment.push(equipment);
              if (stepId) {
                equipment.processSteps = {};
                equipment.processSteps[stepId] = true;
                return this.products.addEquipmentProcessStep(this.product.$id, equipment.id || equipment.$id, stepId);
              }
            });
        }else {
          this.$window.open(`${this.V2_URL}/environmental-monitoring`, '_blank');
        }
      }).catch(err => this.utils.defaultErrorHandler(err, 'Unable to add new equipment.'));
  }

  editControl(hazardId, controlId, stepId) {
    const controlPromise = controlId ?
      this.controlsSvc.$getControl(this.product.$id, controlId) :
      this.controlsSvc.$pushControl(this.product.$id);

    return controlPromise.then(result => {
      this.$selectedControl = result;
      this.$selectedControl.hazards = this.$selectedControl.hazards || {};
      if (hazardId) {
        this.$selectedControl.hazards[hazardId] = true;
        this.hazardSlideCss = 'slide-out-left slide-in-left';
      }
      this.$selectedControl.stepId = this.$selectedControl.stepId || stepId || null;

      this.$timeout(() => {
        this.$stateParams.activeView = 'control';
        this.$stateParams.controlId = this.$selectedControl.$id;
      });
    }).catch(err => this.utils.defaultErrorHandler(err, 'Unable to edit control'));
  }

  addExistingControl(hazard) {
    return this.$uibModal
        .open({
          component: 'cfChooseFromListModal',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            chooseBtnHtml: () => '<i class="far fa-check fa-fw g-mr-5"></i>Choose Control',
            itemName: () => 'control',
            instructionsHtml: () =>
              '<div class="alert alert-info">' +
              '<strong>Note:</strong> Some controls may apply to more than one hazard. If you already added a ' +
              `control that applies to <b>${hazard.name}</b>, select it from the list below.</div>`,
            header: () => '<i class="far fa-exclamation-triangle fa-fw"></i> Choose an Existing Plan Control',
            itemsArray: () =>
                _.map(_.omitBy(this.product.controls, c => c.hazards[hazard.$id]),
                    (c, $id) => _.assign(c, {$id, capType: _.capitalize(c.type)})),
            columns: () => [
              {
                title: 'Type',
                property: 'capType'
              },
              {
                title: 'Measures',
                property: 'measures'
              }
            ]
          }
        }).result.then((control) => this.controlsSvc.addHazardToControl(this.product.$id, hazard.$id, control.$id)
            .then(() => control));
  }

  hazardSaved(hazard, close) {
    if (close) {
      this.cancelEditHazard();
    } else {
      this.growl.success('Hazard saved');
    }
  }

  controlSaved($control, close) {
    if (close) {
      this.cancelEditControl();
    } else {
      this.growl.success('Hazard control saved');
    }
  }

  cancelEditHazard() {
    this.$stateParams.activeView = 'diagram';
    if (this.$selectedHazard) {
      this.$selectedHazard.$destroy();
    }
    this.$selectedHazard = null;
    this.selectedHazard = null;
    this.$stateParams.hazardId = null;

    this.$timeout(() => this.$rootScope.$broadcast('updateDiagram'));
  }

  cancelEditControl() {
    this.$stateParams.activeView = this.$stateParams.hazardId ? 'hazard' : 'diagram';

    this.$timeout(() => {
      this.$selectedControl.$destroy();
      this.$selectedControl = null;
      this.$stateParams.controlId = null;
    });

    this.$timeout(() => {
      this.hazardSlideCss = 'slide-out-right slide-in-right';
    }, this.pageTransitionDelay * 1.2);
  }

  fetchReport() {
    const reportType = this.product.planType === this.planTypes.HACCP_PLAN.id ? 'haccpFull' : 'preventiveControlsFull';

    this.reportsService
      .fetchPlanReport(this.user, this.product, reportType)
      .catch(err => this.utils.defaultErrorHandler(err, 'An error occurred opening the report'));
  }

  onDraggerMove() {
    this.$rootScope.$broadcast('productUiDividerMoved');
  }

  resetTour() {
    this.miniTourService.unsetPlanTipHidden(this.user).then(()=>{
      this.startPlanTour();
    });
  }
}

module.exports = Controller;
