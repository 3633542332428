class Controller {
  constructor($log, $q, subscriptionService, products, constantsService, $uibModal, utils, confirmDeleteModal,
    $window, foodProductService, timerUnits, frequencyConstants, inequalities, growl, InventoryItemsSearch,
    checklistLibraryService, V2_URL) {
    'ngInject';

    this.$log = $log;
    this.productsSvc = products;
    this.subscriptionService = subscriptionService;
    this.$uibModal = $uibModal;
    this.utils = utils;
    this.confirmDeleteModal = confirmDeleteModal;
    this.$q = $q;
    this.$window = $window;
    this.V2_URL = V2_URL;
    this.foodProductService = foodProductService;
    this.timerUnits = timerUnits();
    this.frequencyConstants = frequencyConstants();
    this.inequalities = inequalities();
    this.checklistLibraryService = checklistLibraryService;
    this.growl = growl;
    this.InventoryItemsSearch = InventoryItemsSearch;
    this.logSheetsPromise = constantsService.get('logSheets').then(sheets => {
      this.logSheetNames = _.reduce(sheets, (map, {name}, id) => {
        map[id] = name;
        return map;
      }, {});
      return sheets;
    });
    this.recordKeepingOptions = {
      CF: 'cf',
      MANUAL: 'manual'
    };

    this.temperatureUnits = [
      {name: 'F', value: 'F'},
      {name: 'C', value: 'C'},
    ];
  }

  async $onInit() {
    this.$sop.typeId = 'passFail';
    this.$sop.organizationId = this.$sop.organizationId || this.user.orgContext.id;
    this.$sop.metadata = this.$sop.metadata || {
      productId: this.product.$id,
      controlId: this.$control.$id
    };
    this.organizationId = this.product.organizationId || this.user.organizationId;
    this.foodProductArray = [];
    this.foodProductIdSet = new Set();

    if (this.product.foodProducts) {
      await this.getFoodProductInfo();
    }
    if (!_.isEmpty(this.foodProductIdSet)) {
      this.foodProductValidator();
    }
    this.$sop.metadata.checklists = this.$sop.metadata.checklists || null;
    this.canDoLogs = this.user.doesSubscriptionPermit('logs');
  }

  newItem($event) {
    this.counter += 1;
    if (this.criticalLimitObj.source === 'timerTemp') {
      this.criticalLimitObj.timerTempFields.push({time: '', unit: '', temp: '', tempUnit: '', inequalities: '', foodProduct: ''});
    } else{
      this.criticalLimitObj.phFields.push({ph: '', foodProduct: ''});
    }
    this.sopForm.$setDirty();
    $event.preventDefault();
  }

  removeItem($index) {
    if (this.criticalLimitObj.source === 'timerTemp') {
      this.criticalLimitObj.timerTempFields.splice($index, 1);
    } else{
      this.criticalLimitObj.phFields.splice($index, 1);
    }
    this.sopForm.$setDirty();
  }

  async getFoodProductInfo() {
    if (!this.product?.foodProducts) {
      return;
    }

    const foodProductIds = Object.keys(this.product.foodProducts);
    const ingredientIds = foodProductIds.join(',');

    try {
      const searchObject = new this.InventoryItemsSearch(this.organizationId).size(999).itemIds(ingredientIds);
      const inventoryItems = await searchObject.search();

      foodProductIds.forEach(foodProductId => {
        if (this.product.foodProducts[foodProductId]) {
          const foodProductObj = inventoryItems.find(item => item.id === foodProductId || item.$id === foodProductId);

          if (foodProductObj) {
            const value = foodProductObj.$id || foodProductObj.id;
            this.foodProductArray.push({
              name: foodProductObj.itemName,
              value: value,
            });

            this.foodProductIdSet.add(value);
          } else {
            console.warn(`Food product with ID ${foodProductId} not found in inventory items.`);
          }
        }
      });
    } catch (err) {
      console.error("Error in InventoryItemsSearch or processing food products:", err);
    }
  }

  foodProductValidator() {
    let tempObj = {};

    if (this.criticalLimitObj.source === 'timerTemp') {
      tempObj = this.criticalLimitObj.timerTempFields;
    } else if (this.criticalLimitObj.source === 'pH') {
      tempObj = this.criticalLimitObj.phFields;
    }
    _.each(tempObj, element => {
      element.foodProduct = this.foodProductIdSet.has(element.foodProduct) ? element.foodProduct : '';
    });
  }
  openLogSheet(sheetId) {
    this.logSheetsPromise.then(sheets => {
      let chosen = _.find(sheets, (s, id) => id === sheetId);
      const url = `https://static.foodready.ai/static/resources/logFormScreenshot/${chosen.imgName ? chosen.imgName : 'General-Log.jpg'}`;

      return this.$uibModal.open({
        animation: true,
        template: `<style>.modal-content{background-color: transparent; border: none;}
                   .modal-backdrop.in{opacity: 0.8;}
                   img{max-height: 550px; width: auto;} </style>
                   <div class="d-flex justify-content-end">
                   <h1 type="button" class="text-white" ng-click="$close(true)">&times;</h1></div>
                   <div><img class="rounded mx-auto d-block" ng-src=${url} alt="LogSheet Image"></div>`,
        size: 'sd'
      });
    });
  }

  removeLogSheet(sheetId) {
    this.confirmDeleteModal(this.logSheetNames[sheetId])
      .then(() => {
        this.sopForm.$setDirty();
        this.$sop.metadata.logSheets = _.omit(this.$sop.metadata.logSheets, sheetId);
        if (this.$sop.metadata?.tasks && this.$sop.metadata.tasks[sheetId]) {
          delete this.$sop.metadata.tasks[sheetId];
        }
        if (_.isEmpty(this.$sop.metadata.logSheets)) {
          delete this.$sop.metadata.logSheets;
        }
      })
      .catch(err => this.utils.defaultErrorHandler(err, 'Unable to delete log sheet.'));
  }

  addFoodProduct() {
    this.$window.open(`${this.V2_URL}/item-register`, '_blank');
  }
  addChecklist() {
    this.checklistLibraryService.promptAddChecklist(this.user,{noCopyExisting: true, copyMultipleLib: true})
      .then((checklistTemplates) => {
        if(!this.$sop.metadata.checklists) {
          this.$sop.metadata.checklists = [];
        }
        this.sopForm.$setDirty();
        _.each(checklistTemplates, (checklistTemplate) => {
          if(!this.$sop.metadata.checklists.find(checklist =>checklist.templateId === checklistTemplate.copiedTemplateId)) {
            this.$sop.metadata.checklists.push({
              templateId: checklistTemplate.copiedTemplateId,
              name: checklistTemplate.title,
              required: true
            });
          }
        });
      })
      .catch(err => this.utils.defaultErrorHandler(err, 'Unable to select checklist.'));
  }

  removeChecklist(checklist, $index) {
    this.confirmDeleteModal(checklist.name)
      .then(() => {
        this.sopForm.$setDirty();
        this.$sop.metadata.checklists.splice($index, 1);
        if (checklist?.templateId && this.$sop.metadata?.tasks && this.$sop.metadata.tasks[checklist.templateId]) {
          this.$sop.metadata.tasks[checklist.templateId] = null;
        }
        if (_.isEmpty(this.$sop.metadata.checklists)) {
          this.$sop.metadata.checklists = null;
        }
      })
      .catch(err => this.utils.defaultErrorHandler(err, 'Unable to delete Checklist.'));
  }

  addTaskScheduler(taskName,taskId) {
    this.tasks = [];
    if(this.$sop.metadata.tasks && this.$sop.metadata.tasks[taskId]) {
      this.tasks = this.$sop.metadata.tasks[taskId];
    }
    return this.$uibModal.open({
      component: 'frTaskSchedulerModal',
      backdrop: 'static',
      size: 'xl',
      resolve: {
        tasks: () => this.tasks,
        foodProducts: () => this.foodProducts,
        header: () => taskName,
        user: () => this.user,
        members: () => this.members,
      }
    }).result
      .then((result) => {
        if(!this.$sop.metadata.tasks) {
          this.$sop.metadata.tasks = {};
        }
        this.$sop.metadata.tasks[taskId] = result;
        this.sopForm.$setDirty();
        this.onSave();
      })
      .catch(err => this.utils.defaultErrorHandler(err, 'Unable to add task.'));
  }
}

module.exports = Controller;
