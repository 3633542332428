module.exports = function(ngModule) {
  ngModule.factory('products', function(fbutil, User, $q, $firebaseObject, $firebaseArray, moment,
                                        CacheFactory, organizations, $http, sopService, utils, foodProductService) {
    'ngInject';

    let productOrgIdCache, isSampleProductCache;

    if (!CacheFactory.get('productOrgIdCache')) {
      // eslint-disable-next-line new-cap
      productOrgIdCache = CacheFactory('productOrgIdCache', {
        maxAge: 60 * 60 * 1000, // 1 hour
        deleteOnExpire: 'aggressive'
      });
    }

    if (!CacheFactory.get('isSampleProductCache')) {
      // eslint-disable-next-line new-cap
      isSampleProductCache = CacheFactory('isSampleProductCache');
    }

    return {
      $get: function(productId) {
        return $firebaseObject(fbutil.ref('products', productId)).$loaded();
      },

      $reset: function($product) {
        return $product.$ref().once('value').then(snap => {
          if (!snap.exists()) {
            return $q.reject('FB object no longer exists');
          }
          // eslint-disable-next-line angular/no-private-call
          return $product.$$updated(snap);
        });
      },

      getAll: function(organizationId) {
        return $http.get(`/organizations/${organizationId}/products`)
          .then(result => result.data.products);
      },

      get: function(productId) {
        return fbutil.ref('products', productId).once('value').then(fbutil.getValueOrDefault);
      },

      getBrandName: function(productId) {
        return fbutil.ref(`products/${productId}/brandName`).once('value').then(fbutil.getValueOrDefault);
      },

      getStepName: function(productId, stepId) {
        return fbutil.ref(`products/${productId}/processSteps/${stepId}/name`)
          .once('value').then(fbutil.getValueOrDefault);
      },

      getOrganizationId: function(productId) {
        if (productOrgIdCache.get(productId)) {
          return $q.resolve(productOrgIdCache.get(productId));
        }

        return fbutil.ref('products', productId, 'organizationId').once('value')
          .then(fbutil.getValueOrDefault)
          .then((organizationId) => {
            if (organizationId) { productOrgIdCache.put(productId, organizationId); }
            return organizationId;
          });
      },

      isSampleProduct: function(productId) {
        if (isSampleProductCache.get(productId)) {
          return $q.resolve(isSampleProductCache.get(productId));
        }

        return fbutil.ref('products', productId, 'isSampleProduct').once('value')
          .then(fbutil.getValueOrDefault)
          .then((isSampleProduct) => {
            isSampleProductCache.put(productId, !!isSampleProduct);
            return !!isSampleProduct;
          });
      },

      newProduct: function(user) {
        return {
          planType: 'pcPlan',
          organizationId: user.orgContext.id,
          contactId: user.uid,
          contactEmail: user.email,
          private: true,
          createdOn: firebase.database.ServerValue.TIMESTAMP
        };
      },

      /**
       * Create a new product. Also set the new product's ID on the organization record.
       * @param {object} product Product to create
       * @return {Promise} A promise that resolves to the new product ID.
       */
      create: function(product) {
        return fbutil.ref('products').push(product)
          .then(newRef => {
            return $q.all([
              fbutil.ref('organizations', product.organizationId, 'products', newRef.key).set(true),
              fbutil.ref('users', product.contactId, 'products', newRef.key).set(true),
              organizations.setMilestoneAchieved(product.organizationId, organizations.milestones.PLAN_CREATED)
            ]).then(() => newRef.key);
          });
      },

      delete: function({product, user}) {
        return $q.when([
          fbutil.ref('products', product.$id, 'deleted').set(new Date().getTime()),
          fbutil.ref('products', product.$id, 'deletedOn').set(new Date().getTime()),
          fbutil.ref('products', product.$id, 'updatedBy').set(user?.uid || 'system'),
        ])
          .then(() =>
            sopService.getProductSops(product.organizationId, product.$id)
              .then(sops =>
                $q.all(
                  _.map(sops, sop => {
                    const productId = _.get(sop, 'metadata.productId');
                    const sopId = sop?.$id || sop?.id;
                    if (productId === product.$id && sopId) {
                      return sopService.remove(sopId);
                    }
                  }).filter(Boolean)
                )
              )
          )
          .then(() => fbutil.ref('organizations/' + product.organizationId + '/products/' + product.$id).remove())

          .then(() => fbutil.ref('users/' + product.contactId + '/products/' + product.$id).remove())

          .then(() => {
            if (product.groupId) {
              return fbutil.ref('productGroups', product.groupId, 'members', product.$id).remove();
            }
          })
          .then(()=> fbutil.ref('products',product.$id, 'foodProducts').once('value')
            .then(fbutil.getValueOrDefault)
            .then((foodProduct)=> $q.all(_.mapValues(foodProduct,(val, foodProductId) =>
              foodProductService.softDelete(foodProductId)))
            )
          );
      },

      letters: (product) => {
        return $q.all(_.map(product.letters, (val, letterId) => {
          return $firebaseObject(fbutil.ref('letters', letterId)).$loaded();
        }));
      },

      externalFiles: function(productId) {
        return $firebaseArray(fbutil.ref('products', productId, 'externalFiles')).$loaded();
      },

      hasRecentOrPendingLetter: function(productId) {
        let THREE_YEARS_AGO = moment().add(-3, 'years');

        return this.get(productId).then(product => {
          return product && (product.pendingPlanReview ||
            !!product.activeLetter && product.lastPlanReview > THREE_YEARS_AGO.valueOf());
        });
      },

      addPreviousProcessStep: function(productId, processStepId, previousStep) {
        let $processStep = $firebaseObject(fbutil.ref('products', productId,
          'processSteps', processStepId));

        return $processStep.$loaded()
          .then((processStep) => {
            processStep.previousSteps.push(previousStep);
            return processStep.$save();
          })
          .finally(() => $processStep.$destroy());
      },

      pushControl: function($hazard, controlObj) {
        return $hazard.$ref().ref.child('controls').push(controlObj);
      },

      removeControl: function($hazard, controlId) {
        let $control = $firebaseObject($hazard.$ref().ref.child('controls/' + controlId));

        return $control.$remove().finally(() => $control.$destroy());
      },

      /**
       * Add a procedure reference to a hazard control.
       * @param {string} productId The product ID
       * @param {string} controlId The control ID
       * @param {string} procedureId The procedure ID
       * @return {Promise} A promise that resolves to the set result.
       */
      addProcedure: function(productId, controlId, procedureId) {
        return fbutil.ref('products', productId, 'controls', controlId, 'procedure').set(procedureId);
      },

      /**
       * Remove a procedure reference from a hazard control.
       * @param {string} productId The product ID
       * @param {string} controlId The control ID
       * @return {Promise} A promise that resolves to the set result.
       */
      removeProcedure: function(productId, controlId) {
        return fbutil.ref('products', productId, 'controls', controlId, 'procedure').remove();
      },

      $getProcessSteps: function(productId) {
        return $firebaseArray(fbutil.ref('products', productId, 'processSteps').orderByChild('order')).$loaded();
      },

      $getProcessLinks: function(productId) {
        return $firebaseArray(fbutil.ref('products', productId, 'processLinks').orderByChild('order')).$loaded();
      },

      $getProcessStep: function(productId, stepId) {
        return $firebaseObject(fbutil.ref('products', productId, 'processSteps', stepId)).$loaded();
      },

      $getIngredients: function(productId) {
        return $firebaseArray(fbutil.ref('products', productId, 'ingredients')).$loaded();
      },

      $getControls: function(productId) {
        return $firebaseArray(fbutil.ref('products', productId, 'controls')).$loaded();
      },

      $getEquipment: function(productId) {
        return $firebaseArray(fbutil.ref('products', productId, 'equipment')).$loaded();
      },

      updateLastViewed: function(productId, timestamp) {
        return fbutil.ref('products', productId, 'lastView')
          .set(timestamp || firebase.database.ServerValue.TIMESTAMP);
      },

      /**
       * This call is for supplier organizations. Return the files requested by other orgs that are missing.
       * @param {string} organizationId Supplier org id
       * @param {string} productId ProductId
       * @returns {Promise} A promise that resolves to an array of orgs with a subarray of missing files
       */
      getMissingFiles(organizationId, productId) {
        return $http.get(`/organizations/${organizationId}/products/${productId}/missingFiles`)
          .then(result => result.data.missingFileRecs);
      },

      /**
       * Return the number of products that belong to an org.
       * @param {string} organizationId Org to check.
       * @returns {Promise} A promise that resolves to a number count.
       */
      getCount(organizationId) {
        return $http.get(`/organizations/${organizationId}/products?count=true`)
          .then(result => result.data.count);
      },

      /**
       * Add ingredient to product / plan.
       * @param {string} productId The product ID
       * @param {string} ingredientId The ingredient ID
       * @returns {*} A promise resolving to the result of the set operation
       */
      addIngredient(productId, ingredientId) {
        return fbutil.ref('products', productId, 'ingredients', ingredientId).set(true);
      },

      /**
       * Add ingredient to product / plan process step.
       * @param {string} productId The product ID
       * @param {string} ingredientId The ingredient ID
       * @param {string} processStepId The process step ID
       * @returns {*} A promise resolving to the result of the set operation
       */
      addIngredientProcessStep(productId, ingredientId, processStepId) {
        return fbutil.ref('products', productId, 'ingredients', ingredientId, 'processSteps', processStepId).set(true);
      },

      /**
       * Remove an ingredient from a product / plan
       * @param {string} productId The product ID
       * @param {string} ingredientId The ingredient ID
       * @returns {*} A promise resolving to the result of the remove operation
       */
      removeIngredient(productId, ingredientId) {
        return fbutil.ref('products', productId, 'ingredients', ingredientId).remove();
      },

      /**
       * Remove an ingredient from a product / plan process step
       * @param {string} productId The product ID
       * @param {string} ingredientId The ingredient ID
       * @param {string} processStepId The process step ID
       * @returns {*} A promise resolving to the result of the remove operation
       */
      removeIngredientProcessStep(productId, ingredientId, processStepId) {
        return fbutil.ref('products', productId, 'ingredients', ingredientId, 'processSteps')
          .once('value')
          .then(snap => fbutil.getValueOrDefault(snap, true))
          .then(steps => {
            delete steps.$id;

            return _.keys(steps).length === 1 && steps[processStepId] ?
              this.addIngredient(productId, ingredientId) :
              fbutil.ref('products', productId, 'ingredients', ingredientId, 'processSteps', processStepId).remove();
          });
      },

      /**
       * Return ALL SOPs tied to a product's hazards/controls (includes all types).
       * @param {string} productId The productId
       * @return {*} A promise that resolves to an array of SOPs.
       */
      getAllProductSops: function (productId) {
        return this.$get(productId)
          .then(($product) => {
            let product = $product;

            if (!product.controls) { return []; }

            return $q.all(_.reduce(product.controls, (promises, control) => {
              if (control.type === 'other'){ return promises }

              if (control.type === 'sop'){
                if (control.prerequisites) {
                  _.each(Object.keys(control.prerequisites), prerequisite => {
                    if (control.prerequisites[prerequisite]){
                      promises.push(sopService.get(prerequisite));
                    }
                  })
                }
                return promises;
              }
              // For Control Types => CCP/Process Control, Supplier, Sanitation and Allergen
              if (control.procedure) {
                promises.push(sopService.get(control.procedure));
                return promises;
              }
              return promises;
            }, []));
          });
      },

      /**
       * Add equipment to product / plan.
       * @param {string} productId The product ID
       * @param {string} equipmentId The equipment ID
       * @returns {*} A promise resolving to the result of the set operation
       */
      addEquipment(productId, equipmentId) {
        return fbutil.ref('products', productId, 'equipment', equipmentId).set(true);
      },

      /**
       * Add equipment to product / plan process step.
       * @param {string} productId The product ID
       * @param {string} equipmentId The equipment ID
       * @param {string} processStepId The process step ID
       * @returns {*} A promise resolving to the result of the set operation
       */
      addEquipmentProcessStep(productId, equipmentId, processStepId) {
        return fbutil.ref('products', productId, 'equipment', equipmentId, 'processSteps', processStepId).set(true);
      },

      /**
       * Remove equipment from a product / plan
       * @param {string} productId The product ID
       * @param {string} equipmentId The equipment ID
       * @returns {*} A promise resolving to the result of the remove operation
       */
      removeEquipment(productId, equipmentId) {
        return fbutil.ref('products', productId, 'equipment', equipmentId).remove();
      },

      /**
       * Remove equipment from a product / plan process step
       * @param {string} productId The product ID
       * @param {string} equipmentId The equipment ID
       * @param {string} processStepId The process step ID
       * @returns {*} A promise resolving to the result of the remove operation
       */
      removeEquipmentProcessStep(productId, equipmentId, processStepId) {
        return fbutil.ref('products', productId, 'equipment', equipmentId, 'processSteps')
          .once('value')
          .then(snap => fbutil.getValueOrDefault(snap, true))
          .then(steps => {
            delete steps.$id;

            return _.keys(_.values(steps)).length === 1 && steps[processStepId] ?
              this.addEquipment(productId, equipmentId) :
              fbutil.ref('products', productId, 'equipment', equipmentId, 'processSteps', processStepId).remove();
          });
      },

      /**
       * Save a product process flow as a template for re-use
       * @param {object} product The product to save as a template
       * @param {object} templateInfo Template Info
       * @param {string} templateInfo.name The name of the template
       * @param {string} templateInfo.description The description of the template
       * @param {string} templateInfo.id An optional ID of the template. If null, a new ID will be saved.
       * @param {object} options Additional options
       * @param {boolean} options.hazards Also save hazards to template
       * @param {boolean} options.ingredients Also save ingredients to template
       * @param {boolean} options.equipment Also save equipment to template
       * @param {boolean} options.planAnalysis Also save planAnalysis to template
       * @return {Promise} A promise that resolves to the new template ID
       */
      savePlanTemplate: function(product, templateInfo, options) {
        let url = `/organizations/${product.organizationId}/products/${product.$id}/saveTemplate`;
        let parms = [];

        if (options.hazards) { parms.push('hazards=true'); }
        if (options.planAnalysis) { parms.push('planAnalysis=true'); }
        if (options.ingredients) { parms.push('ingredients=true'); }
        if (options.equipment) { parms.push('equipment=true'); }

        return $http.post(`${url}${utils.toQueryParm(parms)}`, {templateInfo})
          .then(result => result.data);
      },

      /**
       * Get a single safety plan template associated with an organization
       * @param {string} [organizationId] The organization ID.
       * @param {string} [templateId] The template ID.
       * @return {Promise} A promise that resolves to a template object
       */
      getPlanTemplate: function(organizationId, templateId) {
        return fbutil.ref(`planTemplates/${organizationId}/${templateId}`).once('value')
            .then(snap => fbutil.getValueOrDefault(snap, true));
      },

      /**
       * Gets the name for a single safety plan template associated with an organization
       * @param {string} [organizationId] The organization ID.
       * @param {string} [templateId] The template ID.
       * @return {Promise} A promise that resolves to a template name
       */
      getTemplateName(organizationId, templateId) {
        return fbutil.ref(`planTemplates/${organizationId}/${templateId}/name`).once('value')
          .then(fbutil.getValueOrDefault);
      },

      /**
       * Get safety plan templates associated with an organization
       * @param {number} [organizationId] The organization ID.
       * @return {Promise} A promise that resolves to array of templates
       */
      getPlanTemplates: function(organizationId) {
        return fbutil.ref(`planTemplates/${organizationId}`).once('value').then(fbutil.getValueOrDefault);
      },

      /**
       * Transfer safety plan templates from one organization to different
       * @param {string} toOrgId The organization ID.
       * @param {object} templateObj plan template object
       * @return {Promise} A promise that resolves to array of templates
       */
      transferPlanTemplate: function(toOrgId, templateObj) {
        $firebaseObject(fbutil.ref('planTemplates', toOrgId)).$loaded()
          .then(($orgPlanTemplate) => {
            $orgPlanTemplate[templateObj.$id] = templateObj;
            $orgPlanTemplate.$save();
          });
      },

      /**
       * Remove a plan template associated with an organization
       * @param {number} [organizationId] The organization ID.
       * @param {number} [templateId] The plan template ID.
       * @return {Promise} A promise that resolves to remove operation
       */
      removePlanTemplate: function(organizationId, templateId) {
        return fbutil.ref(`planTemplates/${organizationId}/${templateId}`).remove();
      },

      /**
       * Return the template id that maps to this product category
       * @param {string} categoryId The product category Id
       * @return {*} A promise that resolves to a plan template Id or null if there is no mapping
       */
      getTemplatesFromCategory(categoryId) {
        return fbutil.ref(`productCategoryTemplates/${categoryId}/planTemplates`).once('value')
          .then(fbutil.getValueOrDefault);
      },

      /**
       * Load a plan template for a given product
       * @param {object} $product Product to load template to
       * @param {string} [templateOrgId] The organization that the template belongs to. Defaults to the product's org.
       * @param {string} templateId The template identifier
       * @param {object} options Additional options
       * @param {boolean} options.hazards Also load hazards from template
       * @param {boolean} options.ingredients Also load ingredients from template
       * @param {boolean} options.equipment Also load equipment from template
       * @param {boolean} options.planAnalysis Also load planAnalysis from template
       * @return {Q.Promise<any> | IRoute | T | TRequest} A promise that is resolved when the template is loaded
       */
      loadTemplate($product, templateOrgId, templateId, options) {
        let url = `/organizations/${$product.organizationId}/products/${$product.$id}/loadTemplate`;

        let parms = [];

        if (options.hazards) { parms.push('hazards=true'); }
        if (options.planAnalysis) { parms.push('planAnalysis=true'); }
        if (options.ingredients) { parms.push('ingredients=true'); }
        if (options.equipment) { parms.push('equipment=true'); }

        return $http.post(`${url}${utils.toQueryParm(parms)}`, {templateOrgId, templateId});
      },

      /**
       * Set the product's chargeId so it's report can be viewed by the customer
       * @param {string} productId Product to set
       * @param {string} chargeId Charge Id
       * @return {*} A promise that is resolved when the chargeId is set
       */
      setChargeId(productId, chargeId) {
        return fbutil.ref(`products/${productId}/chargeId`).set(chargeId);
      },

      /**
       * Push a control from a subsequent step to a $hazard.
       * @param {object} $hazard The Firebase Object hazard
       * @param {object} subsequentStepControl The subsequent step info: stepId, hazardId, etc.
       * @return {*} A promise that is resolved when complete.
       */
      pushSubsequentStepControl($hazard, subsequentStepControl) {
        return $hazard.$ref().ref.child('subsequentStepControls').push(subsequentStepControl);
      },

      pushStep($product, step) {
        return $product.$ref().ref.child('processSteps').push(step);
      },

      $pushStep(productId, step) {
        return $firebaseObject(fbutil.ref(`products/${productId}/processSteps`).push(step)).$loaded();
      },

      unlock: function(productId, uid) {
        return fbutil.ref(`products/${productId}/unlockedBy`).set(uid);
      },

      lock: function(productId) {
        return fbutil.ref(`products/${productId}/unlockedBy`).remove();
      },

      /**
       * Add Food Product to  plan.
       * @param {string} productId The product ID
       * @param {string} foodProductId The Food Product ID
       * @returns {*} A promise resolving to the result of the set operation
       */
      addFoodProduct(productId, foodProductId) {
        return fbutil.ref('products', productId, 'foodProducts', foodProductId).set(true);
      },

      /**
       * Remove Food Product from a  plan
       * @param {string} productId The product ID
       * @param {string} foodProductId The Food Product ID
       * @returns {*} A promise resolving to the result of the remove operation
       */
      removeFoodProduct(productId, foodProductId) {
        return fbutil.ref('products', productId, 'foodProducts', foodProductId).set(false);
      },
    };
  });
};
